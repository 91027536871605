<template>
  <v-dialog v-model="show" persistent width="unset">
    <v-card :color="colorTheme.success" dark>
      <div class="pt-2">
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showDialog", "message", "timeout"],
  emits: ["update:showDialog"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
  },
  methods: {},
  async mounted() {
    setTimeout(() => {
      this.show = false;
    }, this.timeout);
  }
};
</script>
