<template>
  <v-container>
    <redirection-dialog
      :showDialog.sync="success"
      message="Pasword changed successfully."
      :timeout="timeout"
    ></redirection-dialog>
    <v-row align="center" justify="space-around">
      <v-card v-if="!sentVerCode" class="pa-8" min-width="460">
        <v-card-title class="pa-5 pb-8">
          <div class="text-title">Send verification code</div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="form.valid" ref="form" lazy-validation>
            <v-text-field
              label="Username"
              v-model="form.username"
              :rules="form.rules.username"
              outlined
              :color="colorTheme.textField"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            :disabled="!form.valid"
            @click="sendConfirmationCode"
            large
            min-width="100"
            :color="colorTheme.button"
            class="py-4 px-10 white--text"
          >
            Send Code
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else min-width="450">
        <v-card-title class="pa-5 pb-8">
          <div class="text-title">Create new password</div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="form.valid" ref="formSubmit" lazy-validation>
            <v-text-field
              label="Username"
              v-model="formSubmit.username"
              outlined
              :color="colorTheme.textField"
              readonly
            ></v-text-field>
            <v-text-field
              label="Code"
              v-model="formSubmit.code"
              outlined
              :color="colorTheme.textField"
              required
              :rules="formSubmit.rules.code"
            ></v-text-field>
            <v-text-field
              label="New Password"
              v-model="formSubmit.newPassword"
              :append-icon="formSubmit.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="formSubmit.showPassword ? 'text' : 'password'"
              @click:append="formSubmit.showPassword = !formSubmit.showPassword"
              outlined
              :color="colorTheme.textField"
              required
              :rules="formSubmit.rules.password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            :disabled="!formSubmit.valid"
            @click="submitNewPassword"
            large
            min-width="100"
            :color="colorTheme.button"
            class="py-4 px-10 white--text"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <custom-error
      ref="showCustomError"
      :ActionError.sync="ActionError"
      :ActionErrorMessage.sync="ActionErrorMessage"
      card-width="460"
      error-title="Send Code Error"
    ></custom-error>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapState } from "vuex";
import CustomError from "../components/fee-schedule/CustomError.vue";
import RedirectionDialog from "@/components/dialogs/RedirectionDialog.vue";

export default {
  components: {
    CustomError,
    RedirectionDialog,
  },
  data: () => ({
    timeout: 2000,
    success: false,
    sentVerCode: false,
    ActionError: false,
    ActionErrorMessage: "",
    form: {
      valid: true,
      username: "",
      rules: {
        username: [
          u => !!u || "Username is required",
          u => (u || "").trim().length > 0 || "Username is required"
      ],
      },
    },
    formSubmit: {
      valid: true,
      username: "",
      showPassword: false,
      code: "",
      newPassword: "",
      rules: {
        code: [(c) => !!c || "Code is required"],
        password: [
          (p) => !!p || "Password is required",
          (p) =>
            (p && p.length >= 8) ||
            "Password must be at least 8 characters long",
        ],
      },
    },
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    forgotPasswordQuery() {
      if (this.form.username) {
        return { username: this.form.username };
      } else {
        return null;
      }
    }
  },
  methods: {
    sendConfirmationCode() {
      if (this.$refs.form.validate()) {
        const { username } = this.form;
        Auth.forgotPassword(username)
          .then((data) => {
            console.log(data);
            this.formSubmit.username = username;
            this.sentVerCode = true;
            this.refreshCustomError(null);
          })
          .catch((err) => {
            console.log(err);
            this.refreshCustomError(err);
          });
      }
    },
    submitNewPassword() {
      const { username, code, newPassword } = this.formSubmit;
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$router.replace({ name: "Authentication",
              query: { ...this.forgotPasswordQuery },
            });
          }, this.timeout);
        })
        .catch((err) => this.$refs.showCustomError.refresh(err));
    },
    SetErrorMessage(str) {
      if (!this.sentVerCode) {
        return str.message;
      }
    },
    refreshCustomError(error) {
      this.$refs.showCustomError.refresh(error);
    },
  },
  mounted() {
    if (this.$route.query.username) {
      this.form.username = this.$route.query.username;
    }
  },
};
</script>
