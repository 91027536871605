<template>
  <v-row align="center" justify="space-around">
    <v-card v-if="ActionError" class="pa-8" v-bind:min-width="CardWidth">
      <v-card-title v-text="ErrorTitle"></v-card-title>
      <v-card-text class="justify-center" v-text="ActionErrorMessage">
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "customError",
  data() {
    return {};
  },
  emits: [
    "update:CardWidth",
    "update:ErrorTitle",
    "update:ActionError",
    "update:ActionErrorMessage"
  ],
  props: {
    CardWidth: { required: false, type: String },
    ErrorTitle: { required: false, type: String },
    ActionError: { required: false, type: Boolean },
    ActionErrorMessage: { required: false, type: String },
  },
  methods: {
    refresh(error) {
      if (error === null) {
        this.$emit("update:ActionError", false);
        this.$emit("update:ActionErrorMessage", "");
      } else {
        this.$emit("update:ActionError", true);
        if (error === undefined) {
          this.$emit("update:ActionErrorMessage", "Undefined error occured!");
        } else {
          if (error.message === undefined) {
            this.$emit("update:ActionErrorMessage", error);
          } else {
            this.$emit("update:ActionErrorMessage", error.message);
          }
        }
      }
    },
  },
};
</script>
